<template>
<div id="page-traspasos-inventario-recibidos" class="non-printable">
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </div>
  <v-container fluid fill-height class="non-printable">
    <v-row align-center justify-center>
      <v-col>
        <base-material-card color="primary" icon="library_books" title="Traspasos de Inventario Recibidos" class="elevation-1 px-5 py-3">

          <v-card-text>
            <div id="datagrid" v-on:keyup.enter="registros.items = []; $refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');">

              <v-client-table ref="vuetable" :data="registros.items" :columns="columns" :options="options" class="elevation-2 pa-2">
                    <template slot="noResults">
                        <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                    </template>
                    <template slot="fecha" slot-scope="props">
                        {{ parse_date_time(props.row.fecha) }}
                    </template>
                    <template slot="fecha_finalizado" slot-scope="props">
                        {{ parse_date_time(props.row.fecha_finalizado) }}
                    </template>
                    <template slot="articulos" slot-scope="props">
                        <v-chip color="blue" label outlined x-small @click="verArticulos(props.row)">
                            Ver {{ props.row.articulos.length }} artículos
                        </v-chip>
                    </template>
                    <template slot="actions" slot-scope="props">
                        <v-speed-dial direction="left" open-on-hover>
                            <template v-slot:activator>
                            <v-btn x-small fab dark color="primary">
                                <v-icon v-if="fab[props.row._id]">mdi-close</v-icon>
                                <v-icon v-else>reorder</v-icon>
                            </v-btn>
                            </template>

                            <v-btn x-small fab dark color="primary"
                                v-if="['Salida de Origen','En Proceso de Entrada', 'Recibido'].includes(props.row.estatus)"
                                @click.native="imprimirOrdenEntrada(props.row)" title="Orden Entrada" v-tippy>
                                <v-icon>mdi-package-down</v-icon>
                            </v-btn>
                            <v-btn x-small fab dark color="success" @click.native="imprimirOrdenSalida(props.row)" title="Orden Salida" v-tippy>
                                <v-icon>mdi-package-up</v-icon>
                            </v-btn>
                            <v-btn x-small fab dark color="purple darken-1"
                                v-if="props.row.estatus!='Pendiente'"
                                @click.native="editarObservaciones(props.row)" title="Observaciones" v-tippy>
                                <v-icon>mdi-note-plus</v-icon>
                            </v-btn>


                        </v-speed-dial>

                    </template>

                </v-client-table>
                <PaginacionComponent ref="pagination_pw" :props="props_paginacion"></PaginacionComponent>
            </div>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <!-- MODAL ARTICULOS -->
  <v-dialog v-model="modalArticulos" max-width="80%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Artículos en Traspaso de Inventario</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalArticulos = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
          <v-container grid-list-md>
            <v-row>
                <v-col lg="6" md="6" sm="6" v-show="model_vista.usuario_salida!=null && model_vista.usuario_salida!=''">
                    <b>Salida de Origen:</b><br>
                    Usuario:&nbsp; {{model_vista.usuario_salida}}<br>
                    Fecha:&nbsp; {{ parse_date_time(model_vista.fecha_salida) }}<br>
                    Observaciones:&nbsp; {{model_vista.observacion_salida}}
                </v-col>
                <v-col lg="6" md="6" sm="6" v-show="model_vista.usuario_entrada!=null && model_vista.usuario_entrada!=''">
                    <b>Entrada a Destino:</b><br>
                    Usuario:&nbsp; {{model_vista.usuario_entrada}}<br>
                    Fecha:&nbsp; {{ parse_date_time(model_vista.fecha_finalizado) }}<br>
                    Observaciones:&nbsp; {{model_vista.observacion_entrada}}
                </v-col>
            </v-row><br>
            <v-row>
                <v-col>
                    <v-client-table ref="vuetablearticulos" :data="articulos_vista" :columns="columnsArticulosVista" :options="optionsArticulos" class="elevation-2 pa-2">
                        <template slot="cantidad" slot-scope="props">
                            <div style="text-align:right;">{{ props.row.cantidad }}</div>
                        </template>
                        <template slot="cantidad_salida" slot-scope="props"
                            v-if="model_vista.estatus!='Pendiente'">
                            <div style="text-align:right;" :class="props.row.cantidad_salida!=props.row.cantidad?'bg-diferente':'bg-correcto'">{{ props.row.cantidad_salida }}</div>
                        </template>
                        <template slot="cantidad_recibida" slot-scope="props"
                            v-if="['En Proceso de Entrada','Recibido'].includes(model_vista.estatus)">
                            <div style="text-align:right;" :class="props.row.cantidad_recibida!=props.row.cantidad?'bg-diferente':'bg-correcto'">{{ props.row.cantidad_recibida }}</div>
                        </template>
                    </v-client-table>
                </v-col>
            </v-row>
          </v-container>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modalArticulos=false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- MODAL OBSERVACION -->
  <v-dialog v-model="modalObservacion" max-width="50%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">edit</v-icon>&nbsp;
          <span class="subheading">
            <strong>Editar Observaciones</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon class="close_modal">
          <v-icon class="white--text"></v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formobservacion" lazy-validation>
          <v-container grid-list-md>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-textarea outlined label="Observaciones de salida de mercancía" rows="4" v-model="model.observacion_salida" ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-textarea outlined label="Observaciones de entrada de mercancía" rows="4" v-model="model.observacion_entrada" ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modalObservacion=false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>
        &nbsp;&nbsp;
        <v-btn color="success" @click.native="guardarObservaciones()">
          <v-icon>done</v-icon> Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</div>
</template>

<script>
import PaginacionComponent from '@/components/PaginacionComponent.vue';
import Vue from "vue";
import "jsbarcode";
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
export default {
  components: {
    "PaginacionComponent": PaginacionComponent,
  },
  watch: {
    model_filters: {
      handler(val) {
        // this.$nextTick(() => {
        //   this.registros.items = [];
        //   this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        // });
      },
      deep: true
    },
  },
  mounted: function() {
    this.model_filters = this.$refs.vuetable.$refs.table.query;
    if (!this.verificaPermiso('s.inventarios.traspasos')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
    //Para detectar el cambio de estatus por lista y que busque, sin darle enter
    const select = document.getElementsByName('vf__estatus')[0];
    select.addEventListener('change', this.onStatusChanged);
  },
  created: function() {
      this.sucursal_activa = this.$local_storage.get("sb_sucursal","0");
      if(this.sucursal_activa == "0"){ //Si en otra pestaña cambiaron a sucursal TODAS recargamos la pagina
          this.$router.go();            
      }
      this.model.id_sucursal_destino = this.$local_storage.get("sb_sucursal","");
      this.sb_sucursal = this.$local_storage.get("sb_sucursal","");
      this.model.nombre_sucursal_destino = this.$local_storage.get("sb_nombre_sucursal","");

      this.model.usuario = this.$local_storage.get("sb_usuario");
      this.getSucursales();

  },
  data() {
    return {
        sucursal_activa:"",
        isLoading:false,
        sb_sucursal:"",
        model_vista: "",
        model_filters: "",
        props_paginacion: {
            infiniteHandler: this.infiniteHandler,
            total_registros: 0
        },
        fab: [],
        modalObservacion:false,
        modalAjuste:false,
        modalEditable:false,
        modalArticulos:false,
        barcode_orden:"",
        row_selected:"",
        articulos_vista:[],
        menu1:false,
        menu2:false,
        name: "datagrid",
        columns: [
            "clave_traspaso",
            "nombre_sucursal_origen",
            "fecha","usuario","articulos","estatus","fecha_finalizado", "actions"
        ],
        options: {
            filterable: ["clave_traspaso",
                "nombre_sucursal_origen","estatus",
                "fecha","usuario","fecha_finalizado"],
            headings: {
                "clave_traspaso":"Clave Traspaso",
                "nombre_sucursal_origen":"Sucursal Origen",
                "fecha_finalizado":"Fecha Recibido",
                "actions":"Operaciones"
            },
            listColumns: {
                estatus: [
                    {id: "Pendiente",text: "Pendiente"},
                    {id: "En Proceso de Salida",text: "En Proceso de Salida"},
                    {id: "Salida de Origen",text: "Salida de Origen"},
                    {id: "En Proceso de Entrada",text: "En Proceso de Entrada"},
                    {id: "Recibido",text: "Recibido"},

                ],
            }

        },
        breadcrumbs: [{
            text: "Inicio",
            disabled: false,
            to: "/index"
            },
            {
                text: "Inventario",
                href: "",
                disabled: true,
            },
            {
                text: "Traspasos de Inventario Recibidos",
                href: ""
            }
        ],

        update: false,
        modelEditable:{},
        model:{
            _id:"",
            _rev:"",
            type:"lotes_traspasos",
            clave_traspaso:"",
            id_sucursal_origen:"",
            nombre_sucursal_origen:"",
            id_sucursal_destino:"",
            nombre_sucursal_destino:"",
            articulos:[],
            usuario:"",
            fecha:"",
            usuario_salida:"",
            fecha_salida:"",
            usuario_entrada:"",
            fecha_finalizado:"",
            estatus:"Pendiente", //Pendiente, En Proceso de Salida, Salida de Origen, En Proceso de Entrada, Recibido
            //Arreglo de lotes que se afectaron en sucursal destino,
            // esta opción se llena cuando se finaliza el traspaso en la vista de Traspasos
            lotes_destino: [],
        },
        actual:"",
        cantidad:"",
        actualEditable:"",
        cantidadEditable:"",
        id_articulo:"",

        articulo:{
            id_articulo:"",
            nombre_articulo:"",
            codigo_articulo:"",
            cantidad:"",
            lotes:[], //lotes que afecto el articulo en sucursal origen
            estatus:"Pendiente", //Pendiente, Salida, Recibido
            cantidad_salida: 0,  //cantidad real que salió de sucursal origen
            cantidad_recibida: 0,  //cantidad real recibida en destino
            lotes_destino:[], //Esta opción se llena cuando se le da entrada en sucursal destino
        },
        columnsArticulos: [ "codigo_articulo","nombre_articulo","descripcion","cantidad","actions"],
        columnsArticulosVista: [ "codigo_articulo","nombre_articulo","descripcion","cantidad","cantidad_salida","cantidad_recibida"],
        optionsArticulos: {
            filterable: ["codigo_articulo","nombre_articulo","descripcion","cantidad","cantidad_salida","cantidad_recibida"],
            headings: {
                "codigo_articulo":"Código",
                "nombre_articulo":"Artículo",
                "cantidad":"Cantidad a Traspasar",
                "cantidad_salida":"Cantidad Real Salida",
                "cantidad_recibida":"Cantidad Real Recibida",
                "actions":"Opciones",
            },

        },
        rules: {
            required: v => !!v || "Este campo es requerido",
        },
        search: "",
        registros: {
            selected: [],
            items: []
        },
        sucursales:[],
        all_sucursales:[],
        articulos:[],

    };
  },
  methods: {
    onStatusChanged(event) {
            this.registros.items = []; 
            this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        },
      infiniteHandler: function($state) {
      var rows_per_page = 10;
      var page = Math.ceil(this.registros.items.length / rows_per_page); // == 1
      var skip = page * rows_per_page;
      var where = {};
      if(this.sb_sucursal!=null && this.sb_sucursal!="0" && this.sb_sucursal!="")
        where = {"type":"lotes_traspasos","id_sucursal_destino":this.sb_sucursal};

      this.options.filterable.forEach(x => {
        if (this.model_filters[x]) {
          where[x] = {
            "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
          }
        }
      });

      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            limit: rows_per_page,
            skip: skip,
            selector: where,
            use_index:"_design/92d472d413c9c367df6d19b717ff283e5a65992f"
          },
        }).then(response => {
          if (response.data.docs.length) {
            response.data.docs.sort(function (a, b) {
                //Ordenar por fecha más reciente
                return new Date(b.fecha) - new Date(a.fecha);
            });
            var unique_records = new Set(this.registros.items.map(item => item._id));
            unique_records = Array.from(unique_records.values());            
            var nuevos = response.data.docs.filter(e => !unique_records.includes(e._id));
            this.registros.items = this.registros.items.concat(nuevos);            
            this.props_paginacion.total_registros = this.registros.items.length;
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    parse_date(date) {
      if (!date) return null;
      return window.moment(String(date)).format("YYYY-MM-DD");
    },
    parse_date_time(date) {
      if (!date) return null;
      return window.moment(String(date)).format("YYYY-MM-DD HH:mm");
    },
    parse_date_time_mx(date) {
      if (!date) return null;
      return window.moment(String(date)).format("DD-MM-YYYY HH:mm");
    },



    verArticulos: function(row){
        this.articulos_vista = row.articulos;
        this.model_vista = row;
        this.modalArticulos=true;
    },

    getSucursales: function () {
            let data = {
                    "selector": {
                      "type":"sucursales",
                      "estatus": "Activo",
                    },
                    /*"fields": [
                        "nombre", "estatus"
                    ]*/
                    "use_index":"_design/268be5c91c6ac7ca4ba302055e92efd1ee038633"
                    };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',data)
                .then(response => {
                    if (response.data.docs.length > 0){

                        this.all_sucursales = response.data.docs;
                        var filter = response.data.docs.filter(e => {
                            return e._id != this.model.id_sucursal_origen;
                        });
                        this.sucursales = filter;
                    }
                    else
                        this.sucursales = [];

                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener las sucursales.",
                        footer: ""
                    });

                });
        },

    editarObservaciones: function(row){
        this.model = row;
        this.modalObservacion = true;
    },

    guardarObservaciones: function(){
        if (this.$refs.formobservacion.validate()) {
            window.dialogLoader.show('Espere un momento por favor..');

            window.axios
                .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
                .then(response => {
                    window.dialogLoader.showSnackbar('Las observaciones se guardaron correctamente', {
                        color: 'success'
                    });
                })
                .catch(error => {
                    this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al guardar observaciones. Favor de intentar nuevamente.",
                            footer: error
                        });
                }).then(() => {
                    this.modalObservacion = false;
                    this.registros.items = [];
                    this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                    window.dialogLoader.hide();
                });
        }
    },

    imprimirOrdenEntrada: function(row){
        var self = this;

        var img = document.createElement("img");
        JsBarcode(img, row.clave_traspaso, {format: "CODE128"});

        var columns = [
            {title: "Codigo", dataKey: "codigo_articulo"},
            {title: "Artículo", dataKey: "nombre_articulo"},
            {title: "Descripción", dataKey: "descripcion"},
            {title: "Cantidad", dataKey: "cantidad_salida"},
        ];

        //setTimeout(function(){
            var doc = new jsPDF('p', 'mm', 'letter');

            doc.setFontSize(14);
            doc.text('Orden de Entrada', 15, 23);
            doc.setFontSize(10);
            doc.text('Sucursal Origen: '+row.nombre_sucursal_origen, 15, 30);
            doc.text('Sucursal Destino: '+row.nombre_sucursal_destino, 15, 35);
            doc.text('Fecha Elaboración: '+ this.parse_date_time_mx(row.fecha), 15, 40);
            doc.text('Fecha Impresión: '+ this.parse_date_time_mx(window.moment()), 15, 45);
            doc.addImage(img.src, 'PNG', 140, 20, 50, 30);
            doc.autoTable(columns, row.articulos, {
                startY: 60,
            });

            doc.save(row.clave_traspaso+".pdf");
        //},500);
    },
    imprimirOrdenSalida: function(row){
        var self = this;

        var img = document.createElement("img");
        JsBarcode(img, row.clave_traspaso, {format: "CODE128"});

        var columns = [
            {title: "Codigo", dataKey: "codigo_articulo"},
            {title: "Artículo", dataKey: "nombre_articulo"},
            {title: "Descripción", dataKey: "descripcion"},
            {title: "Cantidad", dataKey: "cantidad"},
        ];

        //setTimeout(function(){
            var doc = new jsPDF('p', 'mm', 'letter');

            doc.setFontSize(14);
            doc.text('Orden de Salida', 15, 23);
            doc.setFontSize(10);
            doc.text('Sucursal Origen: '+row.nombre_sucursal_origen, 15, 30);
            doc.text('Sucursal Destino: '+row.nombre_sucursal_destino, 15, 35);
            doc.text('Fecha Elaboración: '+ this.parse_date_time_mx(row.fecha), 15, 40);
            doc.text('Fecha Impresión: '+ this.parse_date_time_mx(window.moment()), 15, 45);
            doc.addImage(img.src, 'PNG', 140, 20, 50, 30);
            doc.autoTable(columns, row.articulos, {
                startY: 60,
                headStyles:{fillColor:[26,189,156]},
            });

            doc.save(row.clave_traspaso+".pdf");
        //},500);
    },

  }
};
</script>
<style scoped>
.footer-hide>>>.VuePagination {
  display: none;
}
.bg-diferente {
    background-color: rgba(238, 92, 92, 0.813) !important;    
}
.bg-correcto {
    background-color: rgb(93, 160, 93) !important;
}

@media print
{
    .non-printable { display: none; }
    .printable { display: block; }
}
</style>
